import React, { useEffect, useState } from 'react';
import './cardDisciplinaFinanciera.css';
import { obtenerDatosSeleccionadosDisciplinaFinanciera } from '../../methods/methodsDisciplinaFinanciera';

const CardDisciplinaFinanciera = ({ selectedItemIndex, dataType }) => {
  const [ data,setData ] = useState([]);
  useEffect(() =>{    
      setData(obtenerDatosSeleccionadosDisciplinaFinanciera(selectedItemIndex));
  },[selectedItemIndex, dataType])

  return (
    <div className='container'>
      <div className='row card-content-anio'>
        {data.map((item, index) => (
          <div key={index} className={`col-sm-${item.pdf_content ? '12' : '6'} col-12`}>
            <div className='card-body card'>
              {item.pdf_content ? (
                <>
                  <div
                    key={index}
                  >
                    <p>
                      <button
                        className='btn btn-primary contenido-button'
                        type='button'
                        data-bs-toggle='collapse'
                        data-bs-target={`#licitacion-${index}`}
                        aria-expanded='false'
                        aria-controls={`licitacion-${index}`}
                      >
                        {item.anio}
                      </button>
                    </p>
                    {item.pdf_content.map((contenido, index2) => (
                      <div
                        key={`${index}-${index2}`}
                        className='collapse multi-collapse armo-collapse'
                        id={`licitacion-${index}`}
                      >
                        {contenido.pdf_collapse ? (
                          <a
                            href={contenido.pdf_collapse}
                            target='_blank'
                            className='btn btn-primary btn-armo-collapse'
                          >
                            {
                              console.log(contenido.pdf_collapse)
                            }
                            {contenido.nombre_collapse}
                          </a>
                        ) : null}
                      </div>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  <h5>{item.anio}</h5>
                  {item.trimestre.map((trimestre, index2) => (
                    <div key={index2} className='card_trimestre'>
                      <p>
                        {trimestre.trimestreNombre}
                        {trimestre.trimestreContenido.map((contenido, index3) => (
                          <div key={index3}>
                            {contenido.excel ? (
                              <span className='badge bg-secondary span-excel'>
                                <a href={contenido.excel} target='_blank'>
                                  XLS
                                </a>
                              </span>
                            ) : null}
                            {contenido.pdf ? (
                              <span className='badge bg-secondary span-pdf'>
                                <a href={contenido.pdf} target='_blank'>
                                  PDF
                                </a>
                              </span>
                            ) : null}
                          </div>
                        ))}
                      </p>
                    </div>
                  ))}
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default CardDisciplinaFinanciera;