export const carouselInicio = [
    {
        "imagen" : "./assets/images/PREPARTE1.jpg"
    },
    {
        "imagen" : "./assets/images/PREPARTE2.jpg"
    },
    {
        "imagen" : "./assets/images/PREPARTE3.jpg"
    },
    {
        "imagen" : "./assets/images/PREPARTE4.jpg"
    }
]