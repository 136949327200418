import React, { useState, useEffect } from 'react';
import './ofertaeducativa.css';
import { Link } from 'react-router-dom';


const OfertaEducativa = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);

const [especialidades, setEspecialidades] = useState([])
  useEffect(() => {
    fetch('./assets/json/especialidades.json')
      .then((response) => {
        return response.json()
      })
      .then((especialidades) => {
        setEspecialidades(especialidades)
      })
  }, []);

const [licenciaturas, setLicenciaturas] = useState([])
  useEffect(() => {
    fetch('./assets/json/licenciaturas.json')
      .then((response) => {
        return response.json()
      })
      .then((licenciaturas) => {
        setLicenciaturas(licenciaturas)
      })
  }, []);


  return (
    <div className='container ofertaeducativa-container'>
        <div className='row'>
            <div className='col-12'>
            	<div class="espacio"></div>
                <div className='ofertaeducativa_title'>
                    <h1>Oferta Educativa</h1>
                </div>
            </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <div className='notes_title'>
              <h3 className='title'>Cursos musicales</h3>
              <hr className='hr-gob'/>
            </div>            
            <div className='section_notes'>
               <div class="sec_photo">                  
                   <img src={'./assets/images/OFERTAEDUCATIVA1.jpg'} /> 
               </div>
               <div class="sec_container">                  
                  <div class="sec_function">                     
                    <p class='eth_p_gris'>Estos módulos educativos ofrecen la formación artística básica, la cual permitirán al estudiante desarrollar habilidades musicales y artísticas, las cuales, le ayudarán a desenvolverse de mejor manera en su contexto social, así como desarrollar capacidades y aptitudes necesarias para la vida.</p>
                  </div>
               </div>
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>            
            <div className='notes_title'>
              <h3 className='title'>Bachillerato con Propedéutico en Artes</h3>
              <hr className='hr-gob'/>
            </div>            
            <div className='section_notes'>
               <div class="sec_photo">                  
                   <img src={'./assets/images/OFERTAEDUCATIVA2.jpg'} /> 
               </div>
               <div class="sec_container">                  
                  <div class="sec_function">                     
                     <p class='eth_p_gris'>Este modelo educativo ofrece la formación del bachillerato general con el estudio de asignaturas enfocadas en alguna de las cuatro áreas artísticas; Música, Artes Visuales, Teatro y Danza. Por su naturaleza, proporciona las bases y los conocimientos necesarios para continuar con estudios de nivel superior, tanto en el campo de las artes como en otros campos disciplinares.</p>
                    <p class="eth_p_gris">El principal objetivo del Bachillerato con Propedéutico en Artes es que al finalizar tus estudios, obtengas tu certificado de Bachillerato, el cual es proporcionado por la Secretaría de Educación Pública del Estado de Hidalgo (SEPH), así como, un certificado expedido por la Escuela de Música del Estado de Hidalgo.</p>
                  </div>
               </div>
            </div>
          </div>
        </div>

        <div class="espacio"></div>

         <div className='row'>
            <div className='col-12'>            
            	<div className='ofertaeducativa_title'>                    
            	    <h3>PERFIL DE INGRESO</h3>
            	</div>
            	<p class='eth_p_gris'>El Bachillerato con Propedéutico en Artes está dirigido a aspirantes que deseen cursar el nivel medio superior y que, a su vez, se interesen en desarrollar habilidades y conocimientos artísticos, enfocándose en una área respaldada por el trabajo interdisciplinario orientado en creatividad, emociones y sensibilización social para que empleen sus manifestaciones artísticas en el mundo real, acorde a los valores y principios éticos que la Escuela de Música del Estado de Hidalgo profesa.</p>
            	<h5 class='eth_h5_gold'>Conocimientos:</h5>
            	<ul class="list-group list-group-flush">
            	  <li class="list-group-item">Conocimientos de lectura y redacción</li>
            	  <li class="list-group-item">Conocimientos sobre historia</li>
            	  <li class="list-group-item">Conocimientos de cultura general</li>
            	</ul>
            	<div class='espacio'></div>
            	<h5 class='eth_h5_gold'>Habilidades generales:</h5>
            	<ul class="list-group list-group-flush">
            	  <li class="list-group-item">Pensamiento analítico, crítico y abstracto.</li>
            	  <li class="list-group-item">Colaboración y liderazgo.</li>
            	  <li class="list-group-item">Agilidad y adaptabilidad al cambio.</li>
            	  <li class="list-group-item">Comunicación efectiva.</li>
            	  <li class="list-group-item">Creatividad.</li>
            	  <li class="list-group-item">Trabajo en equipo.</li>
            	  <li class="list-group-item">Autonomía y organización.</li>
            	</ul>
            	<div class='espacio'></div>
            	<h5 class='eth_h5_gold'>Actitudes generales y valores:</h5>
            	<ul class="list-group list-group-flush">
            	  <li class="list-group-item">Interés y pasión por el arte.</li>
            	  <li class="list-group-item">Actitud propositiva, ética, de respeto e interés para el estudio, el trabajo y el diálogo académico.</li>
            	  <li class="list-group-item">Actitud ética y de respeto hacia maestros, compañeros y comunidad de la institución.</li>
            	  <li class="list-group-item">Disposición para la investigación en fuentes y archivos.</li>
            	  <li class="list-group-item">Disposición para la composición y la práctica de la producción artística.</li>
            	  <li class="list-group-item">Disposición para la colaboración entre artistas de distintas disciplinas y diferentes áreas de conocimiento.</li>
            	  <li class="list-group-item">Respeto irrestricto a la dignidad de las personas.</li>
            	  <li class="list-group-item">Disposición al desarrollo armónico de las facultades y libertades del ser humano.</li>
            	  <li class="list-group-item">Responsabilidad social y participación activa en la transformación social, basada en los valores éticos.</li>
            	  <li class="list-group-item">Disposición hacia la búsqueda de la formación colectiva.</li>
            	  <li class="list-group-item">Disposición al desarrollo integral a través de la transversalidad de recursos.</li>
            	</ul>
            	<div class='espacio'></div>
            	<h5 class='eth_h5_gold'>Requisitos</h5>
            	<p class="eth_p_gris">Para acceder al Bachillerato con Propedéutico en Artes es necesario:</p>
            	<ul class="list-group list-group-flush">
            	  <li class="list-group-item"><h5><b>a) Realizar el proceso de admisión en las fechas establecidas en la convocatoria vigente</b></h5>
            	    <ul class="list-group list-group-flush">
            	      <li class="list-group-item">Asistir a las pláticas informativas.</li>
            	      <li class="list-group-item">Presentar y acreditar el examen de admisión.</li>
            	      <li class="list-group-item">Presentar el examen psicométrico.</li>
            	      <li class="list-group-item">Presentar el examen de habilidades.</li>
            	    </ul>
            	  </li>
            	  <li class="list-group-item"><h5><b>b) Haber egresado del nivel básico anterior inmediato con un promedio mínimo de 7.0</b></h5></li>
            	  <li class="list-group-item"><h5><b>c) Contar con la siguiente documentación en original:</b></h5>
            	    <ul class="list-group list-group-flush">
            	      <li class="list-group-item">Acta de Nacimiento actualizada</li>
            	      <li class="list-group-item">Certificado de estudios o constancia escolar</li>
            	      <li class="list-group-item">Certificado médico</li>
            	      <li class="list-group-item">CURP actualizada</li>
            	      <li class="list-group-item">NSS del sustentante</li>
            	      <li class="list-group-item">Carta de legitimidad de documentos</li>
            	      <li class="list-group-item">Tres fotografías tamaño infantil</li>
            	    </ul>
            	  </li>
            	</ul>
           		<div class='espacio'></div>
           		<h5 class='eth_h5_gold'>Costos</h5>
           		<ul class="list-group list-group-flush">
           		  <li class="list-group-item">Revisar documento de cuotas y tarifas</li>
           		</ul>
           		<div class='espacio'></div>
           		<h5 class='eth_h5_gold'>Continuidad</h5>
           		<ul class="list-group list-group-flush">
           		  <li class="list-group-item">Educación nivel superior (cualquier área).</li>
           		  <li class="list-group-item">Educación a nivel superior de Música, Artes Visuales, Teatro y Danza.</li>
           		</ul>
           		<object width="100%" height="400" data="./assets/pdf/Bachillerato general.pdf" type="application/pdf"></object>
           		<div class='espacio'></div>
           		<div className='row'>
		          <div className='col-12'>
		            <div className='notes_title'>
		              <h3 className='title'>Especialidades del Propedéutico en Artes</h3>
		              <hr className='hr-gob'/>
		            </div>
		            {
                		especialidades.map((item) =>(
	                    	<>
					            <div className='section_especialidades'>
				                  	<div className='sec_photo'>		                      
				                  	  <img src={ item.imagen } />
				                  	</div>
				                  	<div className='sec_container'>
				                  	  <div className='sec_function'>
				                  	    <h4>{ item.titulo }</h4>				                  	    
				                  	    <div className='btn_agenda'>	                  	    
				                  	      <Link to={ item.link } target="_blank">Conoce el perfil de ingreso y egreso</Link>
				                  	    </div>
				                  	  </div>
				                  	</div>
					            </div>
								<div class='espacio'></div>
							</>
                		))
              		}		            
		          </div>
		        </div>
		        <div class='espacio'></div>
           		<div className='row'>
		          <div className='col-12'>
		            <div className='notes_title'>
		              <h3 className='title'>Licenciatura en Música</h3>
		              <hr className='hr-gob'/>
		            </div>
		            <div class="text-center">
	                	<img src={'./assets/images/LICENCIATURAMUSICA.jpg'} className='img-fluid'/>
		            </div>
		            <div class="salto"></div>
	              	<p class="eth_p_gris">Para ingresar a la Licenciatura en Música, en cualquiera de sus áreas, es necesario cursar, en su totalidad, el Propedéutico de un año o haber estudiado el Bachillerato con Propedéutico en Artes que ofrece la Escuela de Música del Estado de Hidalgo.</p>		            
		          </div>
		        </div>
		        <div class='espacio'></div>
		        <div className='row'>	
					{licenciaturas.map((licenciaturas) => {
				    	return (
				    	<>
				    	<div className='col-md-4 col-sm-6 col-12 transparencia_item'>
				        	<div className='sec_container'>
				          		<div className='sec_function'>
				          	    	<h4>{ licenciaturas.titulo }</h4>
				          	    	<div className='btn_organigrama'>
				          	    		{ licenciaturas.perfil ? <a href={`${licenciaturas.perfil}`} class="btn btn-primary" target="_blank">Perfil de ingreso y egreso</a> : '' }
				          	    		{ licenciaturas.curricula ? <a href={`${licenciaturas.curricula}`} class="btn btn-primary" target="_blank">Currícula</a> : '' }
				          	    	</div>
				          	  	</div>
				          	</div>
				        </div>
				        </>
				    	);
				    })}    
				</div>
              	<div class='espacio'></div>
              	<div className='row'>
		          <div className='col-12'>
		            <div className='notes_title'>
		              <h3 className='title'>Ingeniería en Audio</h3>
		              <hr className='hr-gob'/>
		            </div>
		            <div className='section_especialidades'>
	                  	<div className='sec_photo'>		                      
	                  	  <img src={ './assets/images/INGENIERIAAUDIO.jpg' } />
	                  	</div>
	                  	<div className='sec_container'>
	                  	  <div className='sec_function'>	                  	    
	                  	    <div className='btn_agenda'>	                  	    
	                  	      <Link to={ '../assets/pdf/Perfil de ingreso y egreso Ingeniería en Audio.pdf' } target="_blank">Conoce el perfil de ingreso y egreso</Link>
	                  	      <div class="salto"></div>
	                  	      <Link to={ '../assets/pdf/MapaCurricularIngAudio.pdf' } target="_blank">Currícula</Link>
	                  	    </div>
	                  	  </div>
	                  	</div>
		            </div>
		          </div>
		        </div>
		        <div class='espacio'></div>
		        <div className='row'>
		          <div className='col-12'>
		            <div className='notes_title'>
		              <h3 className='title'>Maestría en Composición de Música para Cinematografía</h3>
		              <hr className='hr-gob'/>
		            </div>
		            <div class="text-center">
	                	<img src={'./assets/images/MAESTRIA.jpg'} className='img-fluid'/>
		            </div>
		            <div className='col-md-4 col-sm-6 col-12 transparencia_item'>
			        	<div className='sec_container'>
			          		<div className='sec_function'>
			          	    	<div className='btn_organigrama'>
			          	    		<a href="../assets/pdf/Modificación Perfil de Ingreso Maestría.pdf" class="btn btn-primary" target="_blank">Perfil de ingreso y egreso</a>
			          	    		<a href="../assets/pdf/MapaCurricularMaestria.pdf" class="btn btn-primary" target="_blank">Currícula</a>
			          	    	</div>
			          	  	</div>
			          	</div>
			        </div>
		          </div>
		        </div>
		        <div class='espacio'></div>
		        

            </div>
        </div>


    </div>
  )
}

export default OfertaEducativa