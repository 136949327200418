export const DisciplinaFinancieraAnual = [
    {
        "titulo" : "LDF - 01 Edo. de Situación Financiera Detallado"
    },
    {
        "titulo" : "LDF - 02 Inf. Analítico de la Deuda Pública y Otros Pasivos"
    },
    {
        "titulo" : "LDF - 03 Inf. Analítico de Obligaciones Diferentes de Financiamiento"
    },
    {
        "titulo" : "LDF - 04 Balance Presupuestario"
    },
    {
        "titulo" : "LDF - 05 Edo. Analítico de Ingresos Detallado"
    },
    {
        "titulo" : "LDF - 06 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación por Objeto del Gasto (Capítulo y Concepto)"
    },
    {
        "titulo" : "LDF - 07 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Administrativa"
    },
    {
        "titulo" : "LDF - 08 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Funcional (Finalidad y Función)"
    },
    {
        "titulo" : "LDF - 09 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación de Servicios Personales por Categoría"
    },
    // {
    //     "titulo" : "LDF - 10 Guía de Cumplimiento de la Ley de Disciplina Financiera de las Entidades Federativas y Municipios"
    // },
    {
        "titulo" : "LDF - Informe sobre Estudios Actuariales"
    },
    {
        "titulo" : "LDF - Proyecciones de Ingresos"
    },
    {
        "titulo" : "LDF - Proyecciones de Egresos"
    },
    {
        "titulo" : "LDF - Resultados de Ingresos"
    },
    {
        "titulo" : "LDF - Resultados de Egresos"
    }
]