import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './pada.css';

const Pada = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container Pada-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='Pada_title'>
                    <h1>Programa Anual de Desarrollo Archivístico de la Escuela de Música del Estado de Hidalgo</h1>
                </div>
            </div>
        </div>
        <div className='row'>

          <div class="accordion" id="accordionExample">

           <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne2024"><button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2024" aria-expanded="true" aria-controls="collapseOne2024">2024</button></h2>
              <div id="collapseOne2024" class="accordion-collapse collapse show" aria-labelledby="headingOne2024" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                      <li class="list-group-item">Programa Anual de Desarrollo Archivístico de la Escuela de Música del Estado de Hidalgo (PADA) 2024<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Transparencia/Pada/2024/PADA 2024.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>                       
                    </ul>
                 </div>
              </div>
           </div>

           <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne2023"><button class="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2023" aria-expanded="true" aria-controls="collapseOne2023">2023</button></h2>
              <div id="collapseOne2023" class="accordion-collapse collapse" aria-labelledby="headingOne2023" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                      <li class="list-group-item">Informe Programa de Desarrollo Archivístico 2023 de la Escuela de Música  del Estado de Hidalgo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Transparencia/Pada/2023/Informe PADA.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                      <li class="list-group-item">Programa Anual de Desarrollo Archivístico de la Escuela de Música del Estado de Hidalgo (PADA) 2023<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Transparencia/Pada/2023/Programa Anual de Desarrollo Archivístico de la Escuela de Música del Estado de Hidalgo (PADA) 2023.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>

           <div class="accordion-item">
              <h2 class="accordion-header" id="headingOne2022"><button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne2022" aria-expanded="false" aria-controls="collapseOne2022">2022</button></h2>
              <div id="collapseOne2022" class="accordion-collapse collapse" aria-labelledby="headingOne2022" data-bs-parent="#accordionExample">
                 <div class="accordion-body">
                    <ul class="list-group">
                      <li class="list-group-item">Informe Programa de Desarrollo Archivístico 2022 de la Escuela de Música  del Estado de Hidalgo<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Transparencia/Pada/2022/Informe Programa de Desarrollo Archivístico 2022 de la Escuela de Música  del Estado de Hidalgo.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                      <li class="list-group-item">Programa Anual de Desarrollo Archivístico de la Escuela de Música del Estado de Hidalgo (PADA) 2022<a class="card_trimestre bg-secondary" href="https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Transparencia/Pada/2022/Programa Anual de Desarrollo Archivístico de la Escuela de Música del Estado de Hidalgo (PADA) 2022.pdf" target="_blank" rel="noopener noreferrer"><i class="badge bg-secondary">.pdf</i></a></li>
                    </ul>
                 </div>
              </div>
           </div>

          </div>

        </div>
    </div>
  )
}

export default Pada