import { BrowserRouter, Routes, Route, Switch } from "react-router-dom";
import Inicio from './components/Inicio/Inicio';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import QuienesSomos from './components/QuienesSomos/QuienesSomos';
import OfertaEducativa from './components/OfertaEducativa/OfertaEducativa';
import Estudiantes from './components/Estudiantes/Estudiantes';
import ExtensionVinculacion from './components/ExtensionVinculacion/ExtensionVinculacion';
import Normatividad from './components/Normatividad/Normatividad';
import Transparencia from './components/Transparencia/Transparencia';
import Rubros from './components/Rubros/Rubros';
import DetalleRubro from './components/DetalleRubro/DetalleRubro';
import RubrosHistoricos from './components/RubrosHistoricos/RubrosHistoricos';
import Pada from "./components/Pada/Pada";
import Paaays from "./components/Paaays/Paaays";
import ArmonizacionContable from './components/ArmonizacionContable/ArmonizacionContable';
import { Armonizacion } from './json/armonizacionContable';
import PreguntasFrecuentes from './components/PreguntasFrecuentes/PreguntasFrecuentes';
import DisciplinaFinanciera from './components/DisciplinaFinanciera/DisciplinaFinanciera';
import { DisciplinaFinancieraAnual } from './json/disciplinaFinancieraAnual';

import Error404 from "./components/Error404/Error404";

function App() {
  return (
    <BrowserRouter>
      <Header/>
        <Routes>
          <Route exact path="/" element={ <Inicio /> } />
          <Route exact path="/QuienesSomos" element={ <QuienesSomos/> } />
          <Route exact path="/OfertaEducativa" element={ <OfertaEducativa/> } />
          <Route exact path="/Estudiantes" element={ <Estudiantes/> } />
          <Route exact path="/ExtensionVinculacion" element={ <ExtensionVinculacion/> } />
          <Route exact path="/Normatividad" element={ <Normatividad/> } />
          <Route exact path="/Transparencia" element={ <Transparencia/> } />          
          <Route exact path="/Transparencia/Rubros" element={ <Rubros/> } />
          <Route exact path="/Transparencia/Rubros/DetalleRubro/:nombre" element={ <DetalleRubro/> } />
          <Route exact path="/Transparencia/Rubros/RubrosHistoricos" element={ <RubrosHistoricos/> } />
          <Route exact path="/Transparencia/Pada" element={ <Pada/> } />
          <Route exact path="/Transparencia/Paaays" element={ <Paaays/> } />
          <Route exact path="/ArmonizacionContable" element={ <ArmonizacionContable data={Armonizacion} title="Armonización Contable" dataType="/Armonizacion" /> } />
          <Route exact path="/DisciplinaFinanciera" element={ <DisciplinaFinanciera data={DisciplinaFinancieraAnual} title="Disciplina Financiera" dataType="/DisciplinaFinanciera" /> } />
          <Route exact path="/PreguntasFrecuentes" element={ <PreguntasFrecuentes/> } />
          <Route exact path="*" element={<Error404 /> } />
        </Routes>
      <Footer/>
    </BrowserRouter>
  );
}

export default App;