export const DisciplinaFinancieraAnual = [    
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/1 trim 2023 LDF/Estadosituacionfinanciera.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/2 trim 2023 LDF/Estadosituacionfinanciera.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/3 trim 2023 LDF/Estadosituacionfinanciera.xls"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/1 trim 2022 LDF/Estadosituacionfinanciera.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/2 trim 2022 LDF/Estadosituacionfinanciera.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/3 trim 2022 LDF/Estadosituacionfinanciera.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/4 trim 2022 LDF/Estadosituacionfinanciera.xls"
                    }
                ]
            },
        ],
    }
];
export const DeudaPpasivos = [    
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/1 trim 2023 LDF/Informeanaliticodeladeuda.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/2 trim 2023 LDF/Informeanaliticodeladeuda.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/3 trim 2023 LDF/Informeanaliticodeladeuda.xls"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/1 trim 2022 LDF/Informeanaliticodeladeuda.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/2 trim 2022 LDF/Informeanaliticodeladeuda.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/3 trim 2022 LDF/Informeanaliticodeladeuda.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/4 trim 2022 LDF/Informeanaliticodeladeuda.xls"
                    }
                ]
            },
        ],
    }
];
export const ObrigacionesDiferentesFinanciamientoTrim = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/1 trim 2023 LDF/Informeanaliticootrasobligaciones.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/2 trim 2023 LDF/Informeanaliticootrasobligaciones.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/3 trim 2023 LDF/Informeanaliticootrasobligaciones.xls"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/1 trim 2022 LDF/Informeanaliticootrasobligaciones.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/2 trim 2022 LDF/Informeanaliticootrasobligaciones.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/3 trim 2022 LDF/Informeanaliticootrasobligaciones.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/4 trim 2022 LDF/Informeanaliticootrasobligaciones.xls"
                    }
                ]
            },
        ],
    }
];
export const BalancePresupuestarioTrim = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/1 trim 2023 LDF/Balancepresupuestario.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/2 trim 2023 LDF/Balancepresupuestario.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/3 trim 2023 LDF/Balancepresupuestario.xls"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/1 trim 2022 LDF/Balancepresupuestario.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/2 trim 2022 LDF/Balancepresupuestario.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/3 trim 2022 LDF/Balancepresupuestario.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/4 trim 2022 LDF/Balancepresupuestario.xls"
                    }
                ]
            },
        ],
    }
];
export const EdoAnaliticoIngresosDetalladoTrim  = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/1 trim 2023 LDF/Estadoanaliticoingresos.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/2 trim 2023 LDF/Estadoanaliticoingresos.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/3 trim 2023 LDF/Estadoanaliticoingresos.xls"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/1 trim 2022 LDF/Estadoanaliticoingresos.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/2 trim 2022 LDF/Estadoanaliticoingresos.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/3 trim 2022 LDF/Estadoanaliticoingresos.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/4 trim 2022 LDF/Estadoanaliticoingresos.xls"
                    }
                ]
            },
        ],
    }
];
export const EdoAnaEjercPtoEgresosClasObjetoGastoCapConcepTrim  = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/1 trim 2023 LDF/F6aEAEPEDCOG.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/2 trim 2023 LDF/F6aEAEPEDCOG.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/3 trim 2023 LDF/F6aEAEPEDCOG.xls"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/1 trim 2022 LDF/F6aEAEPEDCOG.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/2 trim 2022 LDF/F6aEAEPEDCOG.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/3 trim 2022 LDF/F6aEAEPEDCOG.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/4 trim 2022 LDF/F6aEAEPEDCOG.xls"
                    }
                ]
            },
        ],
    }
];
export const EdoAnaEjercPtoEgresosClasAdminTrim  = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/1 trim 2023 LDF/F6bEAEPEDCA.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/2 trim 2023 LDF/F6bEAEPEDCA.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/3 trim 2023 LDF/F6bEAEPEDCA.xls"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/1 trim 2022 LDF/F6bEAEPEDCA.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/2 trim 2022 LDF/F6bEAEPEDCA.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/3 trim 2022 LDF/F6bEAEPEDCA.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/4 trim 2022 LDF/F6bEAEPEDCA.xls"
                    }
                ]
            },
        ],
    }
];
export const EdoAnaEjercPtoEgresosClasFuncionalFinalidadFuncionTrim  = [    
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/1 trim 2023 LDF/F6cEAEPEDCF.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/2 trim 2023 LDF/F6cEAEPEDCF.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/3 trim 2023 LDF/F6cEAEPEDCF.xls"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/1 trim 2022 LDF/F6cEAEPEDCF.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/2 trim 2022 LDF/F6cEAEPEDCF.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/3 trim 2022 LDF/F6cEAEPEDCF.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/4 trim 2022 LDF/F6cEAEPEDCF.xls"
                    }
                ]
            },
        ],
    }
];
export const EdoAnaEjercPtoEgresosClasFuncionalServiciosPersonalesCategoriaTrim  = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/1 trim 2023 LDF/F6d EAPCSP.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/2 trim 2023 LDF/F6d EAPCSP.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/3 trim 2023 LDF/F6d EAPCSP.xls"
                    }
                ]
            },
        ],
    },
     {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/1 trim 2022 LDF/F6d EAPCSP.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/2 trim 2022 LDF/F6d EAPCSP.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/3 trim 2022 LDF/F6d EAPCSP.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/4 trim 2022 LDF/F6d EAPCSP.xls"
                    }
                ]
            },
        ],
    }
];
export const GuiaCumpLeyDFEntidadesTrim  = [
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/1 trim 2022 LDF/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/2 trim 2022 LDF/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/3 trim 2022 LDF/"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/4 trim 2022 LDF/"
                    }
                ]
            },
        ],
    }
];
export const EstudActuarialesTrim  = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/1 trim 2023 LDF/F8IEA.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/2 trim 2023 LDF/F8IEA.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2023/3 trim 2023 LDF/F8IEA.xls"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/1 trim 2022 LDF/F8IEA.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/2 trim 2022 LDF/F8IEA.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercero Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/3 trim 2022 LDF/F8IEA.xls"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/4 trim 2022 LDF/F8IEA.xls"
                    }
                ]
            },
        ],
    }
];
export const ProyeccionesIngresos  = [
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Anual",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/Proyecciones/ProyeccionesIngresos.xls"
                    }
                ]
            },
        ],
    }
];
export const ProyeccionesEgresos  = [
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Anual",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/Proyecciones/ProyeccionesEgresos.xls"
                    }
                ]
            },
        ],
    }
];
export const ResultadosIngresos  = [
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Anual",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/Proyecciones/ResultadosIngresos.xls"
                    }
                ]
            },
        ],
    }
];
export const ResultadosEgresos  = [
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Anual",
                "trimestreContenido" : [
                    {
                        "excel" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/DisciplinaFinanciera/2022/Proyecciones/ResultadosEgresos.xls"
                    }
                ]
            },
        ],
    }
];




export const dsciplinaFinacieraAnualCollapce = [
    {
        "anio" : "2022",
        "excel_content" : [
            {
                "nombre_collapse" : "Inf. contable - 01 Edo. de actividades",
                "excel_collapse" : "",
            }
        ]
    }
];