import React, { useEffect } from 'react';
import Carousel from '../Carousel/Carousel';
import { Link } from 'react-router-dom';
import './estudiantes.css';
import { estudiantes_item } from '../../json/estudiantesItem';
import { carouselEstudiantes } from '../../json/carouselestudiantes';

const Estudiantes = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <Carousel bannerData={carouselEstudiantes} search={false} name="banner-principal" />
    <div className='container estudiantes-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='estudiantes_title'>
                    <h1>Estudiantes</h1>
                </div>
            </div>
        </div>
        <div className='row'>
            {
                estudiantes_item.map((item, index)=>(
                    <div key={ index } className='col-md-4 col-sm-6 col-12 estudiantes_item'>
                        
                        <Link target="_blank" to={ item.estudiantes_item_url }>{ item.estudiantes_item_nombre } </Link>
                        <p>{ item.estudiantes_item_descripcion }</p>
                    </div>
                ))
            }
        </div>
    </div>
    </>
  )
}

export default Estudiantes