import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './extensionvinculacion.css';

const ExtensionVinculacion = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container extensionvinculacion-container'>
        <div className='row'>
            <div className='col-12'>
            <div class="espacio"></div>
                <div className='extensionvinculacion_title'>
                    <h1>Extensión y Vinculación</h1>
                </div>
            </div>
        </div>
        <div className='row'>
            <div className='col-12'>            
              <div className='extensionvinculacion_title'>                    
                  <h3>Becas</h3>
              </div>              
              <h5 class='eth_h5_gold'>BECAS INSTITUCIONALES</h5>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Beca de excelencia: <p class='eth_p_gris'>El Bachillerato con Propedéutico en Artes está dirigido a aspirantes que deseen cursar el nivel medio superior y que, a su vez, se interesen en desarrollar habilidades y conocimientos artísticos, enfocándose en una área respaldada por el trabajo interdisciplinario orientado en creatividad, emociones y sensibilización social para que empleen sus manifestaciones artísticas en el mundo real, acorde a los valores y principios éticos que la Escuela de Música del Estado de Hidalgo profesa.</p></li>
                <li class="list-group-item">Beca socioeconómica: <p class='eth_p_gris'>La beca socioeconómica es la destinada a facilitar la estancia en la Escuela a las y los alumnos que por una situación económica precaria se encuentren en situación de deserción. La beca consistirá únicamente en la exención del pago de cuotas de mensualidad en el porcentaje que dictamine el Honorable Comité de Becas.</p></li>
              </ul>
              <div class='espacio'></div>
              <h5 class='eth_h5_gold'>BECAS ESTATALES</h5>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Becas para la transformación: <p class='eth_p_gris'>Tiene el objetivo de reducir la deserción escolar y está dirigida a estudiantes activos.</p></li>
              </ul>
              <div class='espacio'></div>
              <h5 class='eth_h5_gold'>BECAS FEDERALES</h5>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Beca Jóvenes Escribiendo el Futuro: <p class='eth_p_gris'>Dirigida a estudiantes de Instituciones Públicas de Educación Superior del Sistema Educativo Nacional que se encuentren en condiciones de vulnerabilidad, a fin de iniciar o continuar sus estudios de nivel superior.</p></li>
              </ul>
              <h5><b>Todas las becas y programas de apoyo están sujetas a disponibilidad presupuestal.</b></h5>
              <div class='espacio'></div>
              <div className='extensionvinculacion_title'>                    
                  <h3>Servicio social</h3>
              </div>
              <p class="eth_p_gris">El Servicio Social es la realización obligatoria de actividades temporales que ejecuten las y los estudiantes de carreras profesionales, tendientes a la aplicación de sus conocimientos en beneficio de la sociedad y del Estado; siendo este trámite necesario para obtener su título profesional. Pudiendo realizar servicio social en instituciones gubernamentales o no gubernamentales.</p>
              <p class="eth_p_gris">El objetivo es fomentar, en el alumnado, un espíritu de solidaridad y conciencia de responsabilidad para ayudar en los mejoramientos sociales, culturales y económicos, en beneficio de la entidad.</p>
              <p class="eth_p_gris">El tiempo a cubrir son 480 horas, en un lapso no menor a 6 meses y no mayor a 2 años.</p>
              <div class='espacio'></div>
              <h5 class='eth_h5_gold'>REQUISITOS:</h5>
              <ul class="list-group list-group-flush">
                <li class="list-group-item">Haber acreditado el 70% de las asignaturas del programa académico cursado.</li>
                <li class="list-group-item">Una fotografía digital, blanco y negro, de frente con retoque y vestimenta formal (NO INSTANTÁNEAS).</li>
                <li class="list-group-item">Considerar, por lo menos, dos cuatrimestres para realizar este proceso. Esto con el fin de que programes tus materias de tal modo que no afecten tu compromiso de Servicio Social con la Institución Prestataria donde colaboras, para no ser sancionado.</li>
                <li class="list-group-item">Acudir a la Oficina de Extensión y Vinculación en los meses de enero y junio para iniciar tu proceso de servicio social, en las instituciones que se encuentran dadas de alta.</li>
              </ul>
              <div class='espacio'></div>
              <h5 class='eth_h5_gold'>INSTITUCIONES O EMPRESAS DONDE PUEDES REALIZAR SERVICIO SOCIAL:</h5>
              <ul class="list-group list-group-flush">
                <li class="list-group-item"><h5>Licenciatura en Música</h5>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">Escuela de Música del Estado de Hidalgo</li>
                    <li class="list-group-item">Centro de las Artes de Hidalgo</li>
                    <li class="list-group-item">Casa de las y los Adolescentes</li>
                    <li class="list-group-item">Bachillerato Tecnológico en Artes Byron Gálvez</li>
                    <li class="list-group-item">Preparatoria Cristóbal Colón A.C.</li>
                    <li class="list-group-item">Universidad Tecnológica del Valle del Mezquital</li>
                  </ul>
                </li>
                <li class="list-group-item"><h5>Ingeniería en Audio</h5>
                  <ul class="list-group list-group-flush">
                    <li class="list-group-item">Teatro Cedrus (Misión de Innovación y Desarrollo Integral)</li>
                    <li class="list-group-item">Decibel 100</li>
                    <li class="list-group-item">Dirección General De Innovación Y Emprendimiento Cultural De La   Secretaría De Cultura</li>
                    <li class="list-group-item">AZPROTEC S.A de C. V.</li>
                    <li class="list-group-item">Radio y TV de Hidalgo</li>
                    <li class="list-group-item">Om el sonido de tu vida</li>
                    <li class="list-group-item">Jaime Pérez Sánchez (Grupo los Pérez)</li>
                    <li class="list-group-item">LLAGA Creative & Sound Design</li>
                    <li class="list-group-item">Escuela de Música del Estado de Hidalgo</li>
                    <li class="list-group-item">Centro de Documentación e Investigación Hidalguense</li>
                    <li class="list-group-item">Conservatorio de Música Baudelio Serafín Sosa (Zacatlán)</li>
                    <li class="list-group-item">Empresa Radiofónica Grupo Siete Pachuca.</li>
                  </ul>
                </li>
              </ul>
              <div class='espacio'></div>
              <div className='extensionvinculacion_title'>                    
                  <h3>Convenios</h3>
              </div>
              <p class="eth_p_gris">La Escuela de Música del Estado de Hidalgo, mantiene un convenio de colaboración con la Academia de Arte de Florencia, formalizando su primer acuerdo académico con una institución homóloga, fuera del estado y con proyección internacional.</p>
              <div class="text-center">
                <img src={'./assets/images/CONVENIOS1.jpg'} className='img-fluid'/>
              </div>
              <p class="eth_p_gris">La Escuela de Música del Estado de Hidalgo, mantiene un convenio de colaboración con la Universidad Autónoma de Aguascalientes, para promover el trabajo en conjunto, así como el desarrollo de proyectos académicos, que beneficien al estudiantado y plantilla docente de ambas instituciones.</p>
              <p class="eth_p_gris">Convenio de alianza con Radio y Televisión de Hidalgo para promover a las y los creadores hidalguenses, en todas sus manifestaciones artísticas; así como fortalecer la difusión, cobertura, producción y transmisión de la diversidad y riqueza cultural de la entidad. El convenio incluye una iniciativa para que estudiantes de la Escuela de Música del Estado de Hidalgo, lleven a cabo su servicio social en las instalaciones de esta cadena; además de un impulso en la profesionalización de las y los egresados que ya laboran en este sistema estatal de comunicaciones.</p>
              <p class="eth_p_gris">Convenio marco entre la Secretaría de Cultura y la Secretaría de Educación Pública del Estado de Hidalgo, para sentar la bases y mecanismos de colaboración se generen iniciativas y proyectos que abonen al cumplimiento de los acuerdos que forman parte del Plan Estatal de Desarrollo.</p>
              <p class="eth_p_gris">A partir del Convenio anterior se presenta el convenio con el Bachillerato de Estado de Hidalgo para implementar la educación medio superior.</p>
              <div class="text-center">
                <img src={'./assets/images/CONVENIOS2.jpg'} className='img-fluid'/>
              </div>
            </div>

        </div>
    </div>
  )
}

export default ExtensionVinculacion