import React, { useEffect } from 'react';
import Carousel from '../Carousel/Carousel';
// import UltimasNoticias from '../UltimasNoticias/UltimasNoticias';
import './inicio.css';
import { Link } from 'react-router-dom';
import { carouselInicio } from '../../json/carousel';

const Inicio = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Carousel bannerData={carouselInicio} search={true} name="banner-principal" />
      <div className='container'>       
        
        {/*<UltimasNoticias/>*/}
        
        <div className='row'>
          <div className='col-12'>
            <div className='section_map'>
              <div className='map_name'>
                <h3 className='title'>Últimas noticias</h3>
                <hr className='hr-gob'/>
              </div>
              <iframe width="100%" height="350" src="https://www.youtube.com/embed/jRqvF8tZ_vM" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>              
            </div>
          </div>
        </div>

        <div className='row'>
          <div className='col-12'>
            <div className='section_map'>
              <div className='map_name'>
                <h3 className='title'>Ubicación</h3>
                <hr className='hr-gob'/>
              </div>
              <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7492.530819389513!2d-98.730528!3d20.122972!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d109e68bd9fbe3%3A0xac48288d24a08f7d!2sEscuela%20de%20M%C3%BAsica%20del%20Estado%20de%20Hidalgo!5e0!3m2!1ses-419!2smx!4v1689648675276!5m2!1ses-419!2smx"
                      width="100%" 
                      height="300" 
                      frameborder="0" 
                      style={{border:0, marginBottom:50}} 
                      allowfullscreen>
              </iframe>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <div class="section_map">
              <div class="map_name">
                <h3 class="title">Redes Sociales</h3>
                <hr class="hr-gob"/>
              </div>
              <div class="secretaria-redes-gold">
                <a href="https://youtube.com/@escuelademusicadelestadode4213" target="_blank"><i class="fa-brands fa-youtube"></i></a>
                <a href="https://www.facebook.com/hidalgoemeh" target="_blank"><i class="fa-brands fa-facebook-f"></i></a>
                <a href="https://twitter.com/EMEHoficial" target="_blank"><i class="fa-brands fa-twitter"></i></a>
                <a href="https://www.instagram.com/emehoficial/" target="_blank"><i class="fa-brands fa-instagram"></i></a>
                <a href="https://www.tiktok.com/@emehoficial" target="_blank"><i class="fa-brands fa-tiktok"></i></a>                              
              </div>
            </div>
          </div>
        </div>

        <div class="espacio"></div>

      </div>
    </>
  )
}

export default Inicio