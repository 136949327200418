export const normatividad_item = [
    
    {
        "normatividad_item_nombre" : "a) Decreto de creación",
        "normatividad_item_descripcion" : "",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Normatividad/Modificación del decreto.pdf"
    },
    {
        "normatividad_item_nombre" : "b) Estatuto orgánico",
        "normatividad_item_descripcion" : "",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Normatividad/PUBLICACION ESTATUTO Y CODIGO (2).pdf"
    },
    {
        "normatividad_item_nombre" : "c) Reglamento escolar",
        "normatividad_item_descripcion" : "",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Normatividad/Reglamento Escolar.pdf"
    },
    {
        "normatividad_item_nombre" : "d) Reglamento de becas",
        "normatividad_item_descripcion" : "",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Normatividad/Reglamento de Becas.pdf"
    },
    {
        "normatividad_item_nombre" : "e) Reglamento de titulación",
        "normatividad_item_descripcion" : "",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Normatividad/.pdf"
    },
    {
        "normatividad_item_nombre" : "f) Reglamento de servicio social",
        "normatividad_item_descripcion" : "",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Normatividad/.pdf"
    },
    {
        "normatividad_item_nombre" : "g) Manual de organización",
        "normatividad_item_descripcion" : "",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Normatividad/MOEMEH.pdf"
    },
    {
        "normatividad_item_nombre" : "h) Código de conducta",
        "normatividad_item_descripcion" : "",
        "normatividad_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Normatividad/2022_may_23_alc3_21.pdf"
    }
]