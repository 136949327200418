import { BalancePresupuestarioTrim, DeudaPpasivos, DisciplinaFinancieraAnual, EdoAnaEjercPtoEgresosClasAdminTrim, EdoAnaEjercPtoEgresosClasFuncionalFinalidadFuncionTrim, EdoAnaEjercPtoEgresosClasFuncionalServiciosPersonalesCategoriaTrim, EdoAnaEjercPtoEgresosClasObjetoGastoCapConcepTrim, EdoAnaliticoIngresosDetalladoTrim, EstudActuarialesTrim, GuiaCumpLeyDFEntidadesTrim, ObrigacionesDiferentesFinanciamientoTrim, ProyeccionesEgresos, ProyeccionesIngresos, ResultadosEgresos, ResultadosIngresos, dsciplinaFinacieraAnualCollapce } 
from "../json/anioDisciplinaAnual";

//Ley de Disciplina Finaciera Trimestral

export const obtenerDatosSeleccionadosDisciplinaFinanciera = (selectedItemIndex) => {
  let datosSeleccionadosDisciplina = [];

  switch (selectedItemIndex) {
    case 'LDF - 01 Edo. de Situación Financiera Detallado':
      datosSeleccionadosDisciplina = DisciplinaFinancieraAnual;
      break;
    case 'LDF - 02 Inf. Analítico de la Deuda Pública y Otros Pasivos':
      datosSeleccionadosDisciplina = DeudaPpasivos;
      break;
    case 'LDF - 03 Inf. Analítico de Obligaciones Diferentes de Financiamiento':
      datosSeleccionadosDisciplina = ObrigacionesDiferentesFinanciamientoTrim;
      break;
    case 'LDF - 04 Balance Presupuestario':
      datosSeleccionadosDisciplina = BalancePresupuestarioTrim;
      break;
    case 'LDF - 05 Edo. Analítico de Ingresos Detallado':
      datosSeleccionadosDisciplina = EdoAnaliticoIngresosDetalladoTrim;
      break;
    case 'LDF - 06 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación por Objeto del Gasto (Capítulo y Concepto)':
      datosSeleccionadosDisciplina = EdoAnaEjercPtoEgresosClasObjetoGastoCapConcepTrim;
      break;
    case 'LDF - 07 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Administrativa':
      datosSeleccionadosDisciplina = EdoAnaEjercPtoEgresosClasAdminTrim;
      break;
    case 'LDF - 08 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Funcional (Finalidad y Función)':
      datosSeleccionadosDisciplina = EdoAnaEjercPtoEgresosClasFuncionalFinalidadFuncionTrim;
      break;  
    case 'LDF - 09 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación de Servicios Personales por Categoría':
      datosSeleccionadosDisciplina = EdoAnaEjercPtoEgresosClasFuncionalServiciosPersonalesCategoriaTrim;
      break; 
    case 'LDF - 10 Guía de Cumplimiento de la Ley de Disciplina Financiera de las Entidades Federativas y Municipios':
      datosSeleccionadosDisciplina = GuiaCumpLeyDFEntidadesTrim;
      break; 
    case 'LDF - Informe sobre Estudios Actuariales':
      datosSeleccionadosDisciplina = EstudActuarialesTrim;
      break; 
    case 'LDF - Proyecciones de Ingresos':
      datosSeleccionadosDisciplina = ProyeccionesIngresos;
      break; 
    case 'LDF - Proyecciones de Egresos':
      datosSeleccionadosDisciplina = ProyeccionesEgresos;
      break;   
    case 'LDF - Resultados de Ingresos':
      datosSeleccionadosDisciplina = ResultadosIngresos;
      break; 
    case 'LDF - Resultados de Egresos':
      datosSeleccionadosDisciplina = ResultadosEgresos;
      break;

// Ver en forma de Accordion
    case 'disciplina anual collapse':
      datosSeleccionadosDisciplina = dsciplinaFinacieraAnualCollapce;
      break;
    default:
      datosSeleccionadosDisciplina = [];
  }

  return datosSeleccionadosDisciplina;
};