export const carouselQuienesSomos = [
    {
        "imagen" : "./assets/images/QUIENES_SOMOS1.jpg"
    },
    {
        "imagen" : "./assets/images/QUIENES_SOMOS2.jpg"
    },
    {
        "imagen" : "./assets/images/QUIENES_SOMOS3.jpg"
    }
]