import React, { useEffect } from 'react';
import Carousel from '../Carousel/Carousel';
import { Link } from 'react-router-dom';
import './quienessomos.css';
import { carouselQuienesSomos } from '../../json/carouselquienessomos';

const QuienesSomos = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <>
    <Carousel bannerData={carouselQuienesSomos} search={false} name="banner-principal" />
    <div className='container quienessomos-container'>
        <div className='row'>
            <div className='col-12'>
                <div className='quienessomos_title'>
                    <h1>¿Quienes Somos?</h1>
                </div>
                <p class='eth_p_gris'>En el año 2000, nace La Escuela de Música de Todos los Tiempos, como un proyecto del Sistema DIF Hidalgo. En el año 2004, por medio del Decreto de creación Publicado en el Periodico Oficial del Gobierno del Estado, surge la Escuela de Música del Estado de Hidalgo como un Organismo Público Descentralizado, sectorizado a la Secretaría de Educación Pública de Hidalgo y en 2017, a la Secretaría de Cultura de Hidalgo.</p>
                <p class='eth_p_gris'>La escuela inicia ofertando cursos de iniciación musical, Ingeniería en Audio y Licenciatura en Música, en las áreas de: composición, ejecución instrumental y pedagogía especializada.</p>
                <p class='eth_p_gris'>Se observa un interés por las ciencias cinematográficas, encontrando un área de oportunidad entre la música, la producción y el cine. Es por ello que, en el año 2017, se apertura la Maestría de Composición de Música para Cinematografía.</p>
                <p class='eth_p_gris'>Atendiendo las necesidades de infancias y juventudes por una educación artística formativa, en el año 2018, se crea el Propedéutico en Artes, el cual, luego de un estudio la pertinencia por tener estudios artísticos con educación media superior, dio como resultado el Bachillerato con Propedéutico en Artes, en mayo de 2023.</p>        
            </div>
        </div>

        <div className='row'>
            <div className='col-12'>
                <div className='quienessomos_titulo'>
                  <h3 class="title">Visión</h3>
                  <hr class="hr-gob"/>
                </div>
                <p class='eth_p_gris'>Formar profesionales en las diferentes dimensiones y formas de las disciplinas artísticas y el audio. Las actividades promueven el estudio en todos sus niveles educativos, así como la producción de arte en sus distintas expresiones y proyectos sonoros, enfatizando el análisis, la creatividad, la comunicación y la gestión de proyectos, atendiendo, por igual, tanto el progreso tecnológico como la sensibilización artística y cultural; proporcionando mayores oportunidades para el desarrollo de talentos y aptitudes, por medio de servicios educativos competitivos.</p>
            </div>
        </div>

        <div className='row'>
            <div className='col-12'>
                <div className='quienessomos_titulo'>
                  <h3 class="title">Misión</h3>
                  <hr class="hr-gob"/>
                </div>
                <p class='eth_p_gris'>Constituirse como la Casa de Estudios de mayor jerarquía académica de Hidalgo, especializada en las artes y el audio, desarrollando perspectivas teóricas y propuestas creativas e innovadoras; que constituyan, en la educación, los requerimientos y necesidades del país, así como las exigencias profesionales del mundo laboral.</p>
            </div>
        </div>

        <div className='row'>
            <div className='col-12'>
                <div className='quienessomos_titulo'>
                  <h3 class="title">Objetivo</h3>
                  <hr class="hr-gob"/>
                </div>
                <p class='eth_p_gris'>Contribuir en la formación de profesionales, a través de una educación humanista, artística y tecnológica, con la finalidad de preservar la cultura y tradiciones de las diversas regiones que conforman nuestro entorno económico, político y social; así como dotar a las y los estudiantes de conocimientos que coadyuven a la creación e innovación de las artes y el audio, que les permita solucionar problemas y con ello contribuir a la mejora en la calidad de vida de la sociedad.</p>
                <p class='eth_p_gris'>Proporcionar a las y los estudiantes diversas técnicas que les ayuden a desarrollar las capacidades de expresión, reflexión, creación y sensibilidad artística, las cuales les permitan desenvolverse en su contexto social.</p>
            </div>
        </div>

    </div>
    </>
  )
}

export default QuienesSomos