import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import './preguntasfrecuentes.css';

const PreguntasFrecuentes = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
      }, []);
  return (
    <div className='container preguntasfrecuentes-container'>
        <div className='row'>
            <div className='col-12'>
            <div class="espacio"></div>
                <div className='preguntasfrecuentes_title'>
                    <h1>Preguntas Frecuentes</h1>
                </div>
            </div>
        </div>
        <div className='row'>        
          <h5 class='eth_h5_gold'>a) ¿En qué fechas sale la convocatoria?</h5>
          <p class='eth_p_gris'>Las convocatorias para Estimulación Temprana y Cursos Musicales, en las modalidades sabatino y entre semana, se publican en los meses de agosto y diciembre, para iniciar clases en septiembre y enero, respectivamente.</p>
          <p class='eth_p_gris'>Las convocatorias para los niveles medio superior, superior y posgrado, se publican, anualmente, en el mes de mayo.</p>
          <span class='salto'></span>
          <h5 class='eth_h5_gold'>b) ¿En Ingeniería en Audio se cursa un propedéutico?</h5>
          <p class='eth_p_gris'>Ingeniería en Audio es una carrera profesional que no requiere un curso propedéutico, sin embargo, sí se precisa tener conocimientos y/o gusto por la física, electrónica, acústica y música.</p>
          <span class='salto'></span>
          <h5 class='eth_h5_gold'>c) ¿Cuál es la duración de cada oferta educativa?</h5>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">Los programas de Estimulación Temprana y Cursos Musicales, en cualquiera de sus modalidades, tienen duración cuatrimestral.</li>
              <li class="list-group-item">El Bachillerato con Propedéutico en Artes tiene una duración de tres años.</li>
              <li class="list-group-item">La Licenciatura en Música, en sus tres áreas de énfasis, tiene una duración de cuatro años (sin contar el año de Propedéutico, en caso de tomarlo).</li>
              <li class="list-group-item">La Ingeniería en Audio tiene una duración de cuatro años.</li>
              <li class="list-group-item">La Maestría en Composición de Música para Cinematografía tiene una duración de dos años.</li>
            </ul>
          <span class='salto'></span>        
          <h5 class='eth_h5_gold'>d) Contacto, espacio para que los usuarios escriban sus dudas y éstas se envíen al correo electrónico <a href="mailto:informes@emeh.edu.mx">informes@emeh.edu.mx</a></h5>
          <span class='salto'></span>
          <h5 class='eth_h5_gold'>e) ¿Se pueden revalidar materias?</h5>
          <p class='eth_p_gris'>La revalidación de materias es un proceso personal que el estudiante deberá realizar en las instalaciones de la Dirección General de Profesiones, ubicadas en la Ciudad de México.</p>
          <span class='salto'></span>
          <h5 class='eth_h5_gold'>f) ¿Las clases son presenciales o en línea?</h5>
          <p class='eth_p_gris'>Los programas educativos de nivel inicial, medio superior y superior, están diseñados para que sean presenciales.</p>
          <p class='eth_p_gris'>En el caso del posgrado, las clases son 100% en línea.</p>
        </div>
    </div>
  )
}

export default PreguntasFrecuentes