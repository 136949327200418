export const Armonizacion = [
    {
        "titulo" : "Información Contable",
        "subtitulo" : [
            {
                "informacionTitulo" : "1.- Estado de Situación Financiera"
            },
            {
                "informacionTitulo" : "2.- Estado de Actividades"
            },
            {
                "informacionTitulo" : "3.- Estado de Variación en la Hacienda Pública"
            },
            {
                "informacionTitulo" : "4.- Estado de Cambios en la Situación Financiera"
            },
            {
                "informacionTitulo" : "5.- Informe de Pasivos Contingentes"
            },
            {
                "informacionTitulo" : "6.- Notas a los Estados Financieros"
            },
            {
                "informacionTitulo" : "7.- Estado Analítico del Activo"
            },
            {
                "informacionTitulo" : "8.- Estado de Flujos de Efectivo"
            },
            {
                "informacionTitulo" : "9.- Estado Analítico de la Deuda y Otros Pasivos"
            },
            // {
            //     "informacionTitulo" : "j) Conciliacion entre los Ingresos Presupuestarios y Contables"
            // },
            // {
            //     "informacionTitulo" : "k) Conciliacion entre los Egresos Presupuestarios y los Gastos Contables"
            // },
        ]
    },
    {
        "titulo" : "Información Presupuestaria",
        "subtitulo" : [
            {
                "informacionTitulo" : "1.- Estado Analítico de Ingresos"
            },
            {
                "informacionTitulo" : "02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica"
            },
            {
                "informacionTitulo" : "03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto"
            },
            {
                "informacionTitulo" : "04.- Intereses de la Deuda"
            },
            {
                "informacionTitulo" : "05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa"
            },            
            {
                "informacionTitulo" : "06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional"
            },
            {
                "informacionTitulo" : "07.- Endeudamiento Neto"
            },
            //{
            //    "informacionTitulo" : "b) Estado Analítico Presupuesto Egresos CE"
            //},
            // {
            //     "informacionTitulo" : "e) Flujo de fondos (Indicadores de Postura Fiscal"
            // },
        ]
        
    },
    {
        "titulo" : "Información Programática",
        "subtitulo" : [
            {
                "informacionTitulo" : "01.- Gasto por Categoría Programática"
            },
            {
                "informacionTitulo" : "02.- Indicadores de Postura Fiscal"
            },           
            {
                "informacionTitulo" : "03.- Programas y Proyectos de Inversión"
            },
            {
                "informacionTitulo" : "04.- Indicadores de Resultados"
            },
            
        ]
        
    },
    {
        "titulo" : "Cuenta Pública",
        "subtitulo" : [
            {
                "informacionTitulo" : "Información Contable CP"
            },
            {
                "informacionTitulo" : "Información Presupuestal"
            },
            {
                "informacionTitulo" : "Información Programática CP"
            },
            {
                "informacionTitulo" : "Anexos"
            },
            {
                "informacionTitulo" : "Inf. para integrar y consolidar"
            },
            {
                "informacionTitulo" : "Ley de Disciplina Anual"
            },
            {
                "informacionTitulo" : "Información adicional"
            },
            {
                "informacionTitulo" : "Información del decreto no. 90 PEEH"
            },
            {
                "informacionTitulo" : "Información de la LPCGEH"
            },
            
        ]
    },
    // {
    //     "titulo" : "Inventario de Bienes",
    //     "subtitulo" : [
    //         {
    //             "informacionTitulo" : "Inventario de Bienes Muebles"
    //         },
    //         {
    //             "informacionTitulo" : "Inventario de Bienes Imuebles"
    //         },

    //     ]
    // },
    {
        "titulo" : "Normas Título V",
     
    },
    // {
    //     "titulo" : "Ley de Disciplina Financiera",
        
    // },


]

