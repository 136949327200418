export const estudiantes_item = [
    
    {
        "estudiantes_item_nombre" : "a) Calendario escolar",
        "estudiantes_item_descripcion" : "",
        "estudiantes_item_url" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Estudiantes/Calendario EMEH 2022-2023.pdf"
    },
    {
        "estudiantes_item_nombre" : "b) Credencial de estudiante",
        "estudiantes_item_descripcion" : "",
        "estudiantes_item_url" : "https://ruts.hidalgo.gob.mx/ver/667"
    },
    {
        "estudiantes_item_nombre" : "c) Constancia escolar",
        "estudiantes_item_descripcion" : "",
        "estudiantes_item_url" : "https://ruts.hidalgo.gob.mx/ver/7428"
    },
    {
        "estudiantes_item_nombre" : "d) Historial académico",
        "estudiantes_item_descripcion" : "",
        "estudiantes_item_url" : "https://ruts.hidalgo.gob.mx/ver/7427"
    },
    {
        "estudiantes_item_nombre" : "e) Titulación ",
        "estudiantes_item_descripcion" : "",
        "estudiantes_item_url" : "https://ruts.hidalgo.gob.mx/ver/7430"
    },
    {
        "estudiantes_item_nombre" : "f) Conoce más",
        "estudiantes_item_descripcion" : "",
        "estudiantes_item_url" : "https://www.instagram.com/emehoficial/reels/"
    }
]