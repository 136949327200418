// Información Contable
export const estadoSituacionFinanciera = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/01.- Información Contable/01.- Estado de Situación Financiera.pdf"
                    }
                ]
            }
        ],
    }
];
export const estadoActividades = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/01.- Información Contable/02.- Estado de Actividades.pdf"
                    }
                ]
            }
        ],
    }
];
export const estadoVariacionHaciendaPublica = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/01.- Información Contable/03.- Estado de la Variación en la Hacienda Pública.pdf"
                    }
                ]
            }
        ],
    }
];
export const estadoCambiosSituacionFinanciera = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/01.- Información Contable/04.- Estado de Cambios de la Situación Financiera.pdf"
                    }
                ]
            }
        ],
    }
];
export const informePasivosContingentes = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/01.- Información Contable/05.- Pasivos Contingeentes.pdf"
                    }
                ]
            }
        ],
    }
];
export const notasEstadosFinancieros = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/01.- Información Contable/06.- Nota a los Estados Financieros.pdf"
                    }
                ]
            }
        ],
    }
];
export const estadoAnalíticoActivo = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/01.- Información Contable/07.- Estado Analítico del Activo.pdf"
                    }
                ]
            }
        ],
    }
];
export const estadoFlujosEfectivo = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/01.- Información Contable/08.- Estado de Flujos de Efectivo.pdf"
                    }
                ]
            }
        ],
    }
];
export const estadoAnaliticoDeudaOtrosPasivo = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/01.- Información Contable/09.- Estado Analítico de la Deuda y Otros Pasivos.pdf"
                    }
                ]
            }
        ],
    }
];

// Información Presupuestaría
export const estadoAnaliticoIngresos = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/02.- Información Presupuestaria/01.- Estado Analítico de Ingresos.pdf"
                    }
                ]
            }
        ],
    }
];

export const estadoAnaliticoPresupuestoEgresosCE = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/02.- Información Presupuestaria/02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica.pdf"
                    }
                ]
            }
        ],
    }
];

export const estadoAnaliticoPresupuestoEgresosCOG = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/02.- Información Presupuestaria/03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto.pdf"
                    }
                ]
            }
        ],
    }
];

export const InteresesDeuda = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/02.- Información Presupuestaria/04.- Intereses de la Deuda.pdf"
                    }
                ]
            }
        ],
    }
];

export const EstadoAnaliticoPresupuestoEgresosCA = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/02.- Información Presupuestaria/05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa.pdf"
                    }
                ]
            }
        ],
    }
];

export const EstadoAnaliticoPresupuestoEgresosCF = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/02.- Información Presupuestaria/06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional.pdf"
                    }
                ]
            }
        ],
    }
];

export const EndeudamientoNeto = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/02.- Información Presupuestaria/07.- Endeudamiento Neto.pdf"
                    }
                ]
            }
        ],
    }
];

//Información Programática
export const GastoCategoriaProgramatica = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/03.- Información Programatica/01.- Gasto por Categoría Programática.pdf"
                    }
                ]
            }
        ],
    }
];

export const IndicadoresPosturaFiscal = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/03.- Información Programatica/02.- Indicadores de Postura Fiscal.pdf"
                    }
                ]
            }
        ],
    }
];

export const ProgramasProyectosInversion = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/03.- Información Programatica/03.- Programas y Proyectos de Inversión.pdf"
                    }
                ]
            }
        ],
    }
];

export const IndicadoresResultados = [
        {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }        
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T4/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T1/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T2/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T3/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/T4/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T1/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T2/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T3/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2021/T4/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T1/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T2/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T3/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2020/T4/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T1/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T2/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T3/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2019/T4/03.- Información Programatica/04.- Indicadores de Resultados.pdf"
                    }
                ]
            }
        ],
    }
];

//Normas Título V
export const informacionNormasTV = [
    {
        "anio":"2023",
        "pdf_content":[
            {
                "nombre_collapse" : "Iniciativa Ley Ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/TituloV/iniciativaLeyIngresos2023.xlsx",
            },
            {
                "nombre_collapse" : "Ley Ingressos y Pto Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/TituloV/leydeingresosypresupuestodeegresos2023.pdf",
            },
            {
                "nombre_collapse" : "Difusion a la Ciudadania",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/TituloV/NORMAPARALADIFUSIONALACIUDADANIADELALEYDEINGRESOSYDELPRESUPUESTODEEGRESOSAUTORIZADO2023.doc",
            },
            {
                "nombre_collapse" : "Proyecciones Ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/TituloV/proyecciones de ingresos 2023.xlsx",
            },
            {
                "nombre_collapse" : "Proyecciones Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/TituloV/proyeccionesdeegresos2023.xls",
            },
            {
                "nombre_collapse" : "Resultados Egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/TituloV/resultadosdeEGRESOSF72023.xlsx",
            },
            {
                "nombre_collapse" : "Resultados Ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/TituloV/resultadosdeingresosF72023.xlsx",
            },
            {
                "nombre_collapse" : "Proyecto de presupuesto de egresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/TituloV/PROYECTO DEL PRESUPUESTO DE EGRESOS APROBADO 2023.doc",
            },
            {
                "nombre_collapse" : "Bienes muebles e inmueble",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/TituloV/Relacion de bienes muebles.xlsx",
            },
        ]
    },
    // {
    //     "anio":"2022",
    //     "pdf_content":[
    //         {
    //             "nombre_collapse" : "Titulo V 2022.zip",
    //             "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/Titulo V 2022.zip",
    //         },
    //     ]
    // }
];

/*HASTA aqui USO TODO LO DE ARRIBA, LO DE ABAJO HAY QUR REVISARLO*/
/**/


export const edoAnaliticoPrestoEgresosClasifObjetoGasto = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/dir1/2023/T1/01.- Información Contable/EstadodeSitucionFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/dir1/2023/T2/01.- Información Contable/EstadodeSitucionFinanciera.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/dir1/2023/T3/01.- Información Contable/EstadodeSitucionFinanciera.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/T1/Informacion contable/ConciliacionentrelosEgresosPresupuestalesyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];
export const edoAnaliticodelPrestoEgresosClasifEconimica = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];
export const edoAnaliticodelPrestoEgresosClasifAdministrativa = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];





export const EstadoAnaliticoEjercicioPresupuestoEgresos = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/presupuesta/Endeudamiento%20Neto.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/presupuestal/Endeudamiento%20Neto.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/presupuestaria/Endeudamiento%20Neto.PDF"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];
export const EstadoAnaliticoPresupuestoEgresosClasificacionProgramatica = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/presupuestaria/Categoria%20Programatica.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];
export const EstadoAnaliticoEjercicioPresupuestoFuenteFinanciamiento = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/3er_trim/presupuestal/Estado%20Analitico%20Pto%20Egresos%20Fuente%20de%20Financiamiento.PDF"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/4to_trim/presupuestaria/Edo%20Analit%20Pto%20Egresos%20Fuente%20de%20Financiamiento.PDF"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];
export const EstadoFinancieroPresupuestal = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        "excel" : "https://www.google.com",
                        "pdf" : "https://www.google.com"
                    }
                ]
            }
        ],
    }
];





//Otras Obligaciones LGCG

export const ProgramaAnualEvaluaciones = [
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Año 2022",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/1er_trim/t_v/PROGRAMA%20ANUAL%20EVALUACIONES%202022.pdf"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const ResultadoProgramaAnualEvaluaciones = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];

//Inventario de Bienes

export const InventarioBienesMuebles = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/Inventarios/InventarioBienesMuebles.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/anexos/RBMI_IHM_04_2022.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/Inventarios/InventarioBienesMuebles.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/anexos/RBMI_IHM_04_2022.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/Inventarios/InventarioBienesMuebles.pdf",
                    }
                ]
            }
        ],
    },

    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/Inventarios/InventarioBienesMuebles.pdf",
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/anexos/RBMI_IHM_04_2022.xlsx",
                        //"pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/Inventarios/InventarioBienesMuebles.pdf",
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]    
            },
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]    
            }
        ],
    },
    {
       "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const InventarioBienesImuebles = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/Inventarios/InventarioBienesInmuebles.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/anexos/RBMI_IHM_04_2022.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/Inventarios/InventarioBienesInmuebles.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/anexos/RBMI_IHM_04_2022.xlsx",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/Inventarios/InventarioBienesInmuebles.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                       //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];

//Cuenta Pública

export const CuentaPublica2022 = [
    
];

export const InformaciónContableCP = [
    {
        "anio" : "2022",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. contable - 01 Edo. de actividades",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/1.%2001.- Información Contable/01%20EA.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 02 Edo. de situación financiera",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/1.%2001.- Información Contable/02%20ESF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 03 Edo. de variación en la hacienda pública",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/1.%2001.- Información Contable/03%20EVHP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 04 Edo. de cambios en la situación financiera",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/1.%2001.- Información Contable/04%20ECSF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 05 Edo. de flujos de efectivo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/1.%2001.- Información Contable/05%20EFE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 06 Edo. analítico del activo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/1.%2001.- Información Contable/06%20EAA.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 07 Edo. analítico de la deuda y otros pasivos",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/1.%2001.- Información Contable/07%20EADOP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 08 Informe de pasivos contingentes",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/1.%2001.- Información Contable/08%20IPC.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 09 Notas a los Edos. financieros",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/1.%2001.- Información Contable/09%20NEF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. contable - 10 Conciliación entre los ingresos presupuestarios y contables",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/1.%2001.- Información Contable/10%20CIPyCyEPyC.PDF",
                "pdf_collapse" : "/error.pdf",
            }
        ]
    }
];

export const informacionPresupuestalCP = [
    {
        "anio" : "2022",
        "pdf_content" : [
            {
                "nombre_collapse" : "Inf. presupuestal - 01 Edo. analítico de ingresos",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/01%20EAI.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 02 Edo. analítico del ejercicio del presupuesto de egresos clasificación por objeto del gasto (capítulo y concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/02%20EAEPE%20-%20COG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 03 Edo. analítico del ejercicio del presupuesto de egresos clasificación administrativa",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/03%20EAEPE%20-%20CA.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 04 Clasificación económica de los ingresos, de los gastos y del financiamiento",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/04%20CE%20-%20IGF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 05 Edo. analítico del ejercicio del presupuesto de egresos clasificación económica (por tipo de gasto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/05%20EAEPE%20-%20CE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 06 Edo. analítico del ejercicio del presupuesto de egresos clasificación funcional (finalidad y función)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/06%20EAEPE%20-%20CF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 07 Endeudamiento neto",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/07%20EN.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 08 Intereses de la deuda",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/08%20ID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. presupuestal - 09 Indicadores de postura fiscal",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/2.%20INFORMACION%20PRESUPUESTAL/09%20IPF.PDF",
                "pdf_collapse" : "/error.pdf",
            }
        ]
    }
];

export const informacionProgramaticaCP = [
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. programática - 01 Gasto por categoría programática",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/01%20GCP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. programática - 02 Programas y proyectos de inversión",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/02%20PyPI.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. programática - 03 Indicadores de resultados (indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/03%20IR.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    }
];

export const anexosCP = [
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "Anexos - 01 Relación de bienes muebles que componen el patrimonio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/01%20RBM.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Anexos - 02 Relación de bienes inmuebles que componen el patrimonio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/02%20RBI.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Anexos - 03 Relación de cuentas bancarias productivas específicas",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/03%20RCBPE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Anexos - 04 Relación de esquemas bursátiles y de coberturas financieras",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/04%20REByCF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Anexos - 05 Formato del ejercicio y destino de gasto federalizado y reintegros",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/4.%20ANEXOS/05%20FEyGFyR.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    }
];

export const infintegconsol = [
    {
        "anio":"2022",
        "pdf_content":[
            //Información para integrar y consolidar
            {
                "nombre_collapse" : "Inf. para integrar y consolidar - 01 Transferencias, asignaciones, subsidios y otras ayudas",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/01%20%20ITRSFP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. para integrar y consolidar - 02 Aportaciones (del grupo hacienda pública/patrimonio contribuido)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/5.%20INFORMACION%20PARA%20INTEGRAR%20Y%20CONSOLIDAR/02%20APSFP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    }
];

export const leydisciplinafinancieraCP = [
    {
        "anio":"2023",
        "pdf_content":[
            {
                "nombre_collapse" : "LDF - 01 Edo. de Situación Financiera Detallado",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 02 Inf. Analítico de la Deuda Pública y Otros Pasivos ",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 03 Inf. Analítico de Obligaciones Diferentes de Financiamiento ",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 04 Balance Presupuestario ",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 05 Edo. Analítico de Ingresos Detallado ",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 06 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 07 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Administrativa",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 08 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Funcional (Finalidad y Función)",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 09 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación de Servicios Personales por Categoría",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 10 Guía de Cumplimiento de la Ley de Disciplina Financiera de las Entidades Federativas y Municipios",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "LDF - 11 Balance Presupuestario",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/CuentaPublica/BalancepresupuestarioF4BP.xls",
            },
            {
                "nombre_collapse" : "LDF - 12 Ingresos Excedentes",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/CuentaPublica/BalancePresupuestarioNegativoF04IEDILD.xlsx",
            },
            {
                "nombre_collapse" : "LDF - 13 Estado Analitico Ingresos",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/CuentaPublica/EstadoIngresosF5EAID.xls",
            },
            {
                "nombre_collapse" : "LDF - 14 Estudio Actuarial",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/CuentaPublica/EstudiosactuarialesF8.xls",
            },
            {
                "nombre_collapse" : "LDF - 15 Estado Analitico Objeto del Gasto",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/CuentaPublica/FormatoF6a.xls",
            },
            {
                "nombre_collapse" : "LDF - 16 Clasificacion Servicios Personales",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/CuentaPublica/ServiciosPersonalesCSPC.xls",
            },
        ]
    },
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "LDF - 01 Edo. de Situación Financiera Detallado",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/Cuenta Pública/Ley de Disciplina Anual/01.pdf",
            },
            {
                "nombre_collapse" : "LDF - 02 Inf. Analítico de la Deuda Pública y Otros Pasivos ",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/Cuenta Pública/Ley de Disciplina Anual/02.pdf",
            },
            {
                "nombre_collapse" : "LDF - 03 Inf. Analítico de Obligaciones Diferentes de Financiamiento ",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/Cuenta Pública/Ley de Disciplina Anual/03.pdf",
            },
            {
                "nombre_collapse" : "LDF - 04 Balance Presupuestario ",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/Cuenta Pública/Ley de Disciplina Anual/04.pdf",
            },
            {
                "nombre_collapse" : "LDF - 05 Edo. Analítico de Ingresos Detallado ",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/Cuenta Pública/Ley de Disciplina Anual/05.pdf",
            },
            {
                "nombre_collapse" : "LDF - 06 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/Cuenta Pública/Ley de Disciplina Anual/06.pdf",
            },
            {
                "nombre_collapse" : "LDF - 07 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Administrativa",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/Cuenta Pública/Ley de Disciplina Anual/07.pdf",
            },
            {
                "nombre_collapse" : "LDF - 08 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación Funcional (Finalidad y Función)",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/Cuenta Pública/Ley de Disciplina Anual/08.pdf",
            },
            {
                "nombre_collapse" : "LDF - 09 Edo. Analítico del Ejercicio del Presupuesto de Egresos Detallado - Clasificación de Servicios Personales por Categoría",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/Cuenta Pública/Ley de Disciplina Anual/09.pdf",
            },
            {
                "nombre_collapse" : "LDF - 10 Guía de Cumplimiento de la Ley de Disciplina Financiera de las Entidades Federativas y Municipios",
                "pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2022/Cuenta Pública/Ley de Disciplina Anual/10.pdf",
            },
        ]
    },    
];

export const informacionadicionalCP = [
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. Adicional LDF - 01 Balance Presupuestario de Recursos Disponibles Negativo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/01%20BPRDN.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 02 Aumento o Creación del Gasto del Presupuesto de Egresos Durante el Ejercicio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/02%20ACG%20DEL%20PE%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 03 Devengados en el Año que Integra el Pasivo Circulante al Cierre del Ejercicio",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/03%20DAIPCCE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 04 Ingresos Excedentes Derivados de Ingresos de Libre Disposición",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/04%20IEDILD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 05 Contratación de Deuda Pública y Obligaciones",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/05%20CDPyO.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. Adicional LDF - 06 Información Detallada de Contratación de Obligaciones a Corto Plazo",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/7.%20INFORMACION%20ADICIONAL%20LDF/06%20IDCOCP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    }
];

export const informaciondecreto90PEEH = [
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 01 Informe sobre la Situación Económica de las Finanzas Públicas Comparativo del Estado de Situación Financiera",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/01%20Art%2010%20CESF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 02 Informe sobre la Situación Económica de las Finanzas Públicas Comparativo del Estado Analítico del Ejercicio del Presupuesto de Egresos Clasificación por Objeto del Gasto (Capítulo y Concepto)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/02%20Art%2010%20CEAEPE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 03 Informe sobre la Situación Económica de las Finanzas Públicas Informe Ejecutivo (Comparativos Situación Financiera y Ejercicio del Presupuesto de Egresos)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/03%20Art%2010%20IEC.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 04 Informe sobre la Situación Económica de las Finanzas Públicas Estado Analítico de Ingresos Presupuestales por rubro",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/04%20Art%2010%20EAIP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 05 Informe sobre la Situación Económica de las Finanzas Públicas Informe Ejecutivo (Ingresos)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/05%20Art%2010%20IEI.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 06 Informe sobre la Deuda Pública Variación respecto a la Deuda Pública",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/06%20Art%2010%20VDP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 07 Indicadores de Resultados (Erogaciones de la política transversal de incorporación de la ciencia, tecnología e innovación y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/07%20Art%2014%20EPTICTeIyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 08 Indicadores de Resultados (Erogaciones de la política transversal de perspectiva para el desarrollo y protección de niñas, niños y adolescentes y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/08%20Art%2014%20EPTPDyPNNyAyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Resultados (Erogaciones del Programa Estratégico de Respuesta ante Emergencias de Orden Global)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTPEREOG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 09 Indicadores de Resultados (Erogaciones de la política transversal de perspectiva de género y cumplimiento de indicadores de desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTPGyCID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Resultados (Erogaciones de la Política Transversal de Reducción de las Desigualdades y la vulnerabilidad)",
                //"pdf_collapse" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/09%20Art%2014%20EPTRVD.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - Indicadores de Desempeño",
                //"pdf_collapse" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/10%20Art%2014%20ID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 11 Indicadores de Resultados (Avances y resultados de la ejecución y evaluación de los programas presupuestarios)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/11%20Art%2019%20AyREyEPP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 12 Programas, Proyectos, Obras o Acciones con Aportaciones de Recursos por parte del Gobierno del Estado",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/12%20Art%2023%20PPOARGE.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 15 Avance en los Programas de Reducción del Gasto",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/15%20Art%2052%20APRG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. del decreto No. 90 PEEH - 16 Tabuladores y Remuneraciones Cubiertas a los Servidores Públicos",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/8.%20INFORMACION%20DEL%20PEEH/16%20Art%2060%20TyRCSP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    }
];


export const informacionLPCGEH  = [
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 01 Aumento o Creación del Gasto del Presupuesto de Egresos 2Inf. de la LPCGEH - 021 (Etiquetado y No Etiquetado)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/01%20Art%2018%20BIS%20ACG%20.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 06 Contrataciones por Prestación de Servicios Profesionales por Honorarios (Personas Físicas)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/06%20Art%2072%20CPSPH%20-%20PF.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 07 Indicadores de Resultados (Resultados de los Programas de Igualdad de Género)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/07%20Art%2096%20RPIG.PDF",
                "pdf_collapse" : "/error.pdf",
            },
            {
                "nombre_collapse" : "Inf. de la LPCGEH - 08 Indicadores de Resultados (Indicadores de Desempeño)",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/9.%20INFORMACION%20DE%20LA%20LPCGEH/08%20Art%2096%20ID.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    }
];



export const LeyDisciplinaFianaciera = [
    {
        "anio":"2023",
        "pdf_content":[
            {
                "nombre_collapse" : "",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/01%20GCP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    },
    {
        "anio":"2022",
        "pdf_content":[
            {
                "nombre_collapse" : "",
                //"pdf_collapse" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/cp/3.%20INFORMACION%20PROGRAMATICA/01%20GCP.PDF",
                "pdf_collapse" : "/error.pdf",
            },
        ]
    }
];

//Normas (Título Quinta LGCG)

//export const AprobacionLeyIngresosPresupuestos  = [
  

//Programa Anual de Adquisiciones



//Calendario de Gastos

export const CalendarioGastos = [
    {
        "anio" : "Calendario 2022",
        "trimestre" : [
            {
                "trimestreNombre" : "",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "http://transparenciadocs.hidalgo.gob.mx/ENTIDADES/IHMujer/ac/2022/2do_trim/g_o/Calendario%20de%20gastos%202022.pdf"
                    }
                ]
            },
        ],
    },

];

/*export const conciliacionContablePresupuestal = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/01.- Información Contable/ConciliacionentrelosIngresosPresupuestariosyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/01.- Información Contable/ConciliacionentrelosIngresosPresupuestariosyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/01.- Información Contable/ConciliacionentrelosIngresosPresupuestariosyContables.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/T1/Informacion contable/ConciliaciónentrelosIngresosPresupuestalesyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                       "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/T2/Informacion contable/ConciliaciónentrelosIngresosPresupuestalesyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/T3/Informacion contable/ConciliacionentrelosIngresosPresupuestalesycontables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/T4/Informacion contable/ConciliacionentrelosIngresosPresupuestariosyContables.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];
export const conciliacionContablePresupuestalE = [
    {
        "anio" : "2023",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T1/01.- Información Contable/ConciliacionentrelosEgresosPresupuestariosylosGastosContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T2/01.- Información Contable/ConciliacionentrelosEgresosPresupuestariosylosGastosContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/EMusicaEH/dir1/Armonizacion/2023/T3/01.- Información Contable/ConciliacionentrelosEgresosPresupuestariosylosGastosContables.pdf"
                    }
                ]
            },
        ],
    },
    {
        "anio" : "2022",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/T1/Informacion contable/ConciliacionentrelosEgresosPresupuestalesyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/T2/Informacion contable/ConciliacionentrelosEgresosPresupuestalesyContables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/T3/Informacion contable/ConciliacionentrelosEgresosPresupuestalesycontables.pdf"
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        "pdf" : "https://transparenciadocs.hidalgo.gob.mx/ENTIDADES/CECULTA/2022/T4/Informacion contable/ConciliacionentrelosEgresosPresupuestariosylosGastosContables.pdf"
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2021",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2020",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2019",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2018",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2017",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2016",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2015",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    },
    {
        "anio" : "2014",
        "trimestre" : [
            {
                "trimestreNombre" : "Primer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Segundo Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Tercer Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            },
            {
                "trimestreNombre" : "Cuarto Trimestre",
                "trimestreContenido" : [
                    {
                        //"excel" : "https://www.google.com",
                        //"pdf" : "https://www.google.com"
                        "pdf" : "/error.pdf",
                    }
                ]
            }
        ],
    }
];*/