export const transparencia_item = [
    {
        "transparencia_item_nombre" : "48 Rubros",
        "transparencia_item_url" : "/Transparencia/Rubros"
    },
    {
        "transparencia_item_nombre" : "Rubros Anteriores",
        "transparencia_item_url" : "/Transparencia/Rubros/RubrosHistoricos"
    },
    {
        "transparencia_item_nombre" : "Programa Anual de Desarrollo Archivístico de la Escuela de Música del Estado de Hidalgo",
        "transparencia_item_url" : "/Transparencia/Pada"
    },
    {
        "transparencia_item_nombre" : "Armonización Contable",
        "transparencia_item_url" : "/ArmonizacionContable"
    },
    {
        "transparencia_item_nombre" : "Disciplina Financiera",
        "transparencia_item_url" : "/DisciplinaFinanciera"
    },
    {
        "transparencia_item_nombre" : "Programa Anual de Adquisiciones, Arrendamientos y Servicios",
        "transparencia_item_url" : "/Transparencia/Paaays"
    }
]