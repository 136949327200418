import { 
  //Información Contable
  estadoSituacionFinanciera, estadoActividades, estadoCambiosSituacionFinanciera, 
  informePasivosContingentes, notasEstadosFinancieros, estadoAnalíticoActivo, conciliacionContablePresupuestalE,
  estadoFlujosEfectivo, estadoAnaliticoDeudaOtrosPasivo, conciliacionContablePresupuestal, estadoVariacionHaciendaPublica,
  
 //Información Presupuestal
  estadoAnaliticoIngresos, estadoAnaliticoPresupuestoEgresosCE, estadoAnaliticoPresupuestoEgresosCOG, InteresesDeuda,
  EstadoAnaliticoPresupuestoEgresosCA,EstadoAnaliticoPresupuestoEgresosCF, EndeudamientoNeto,
  EstadoAnaliticoEjercicioPresupuestoEgresos, EstadoAnaliticoPresupuestoEgresosClasificacionProgramatica, EstadoAnaliticoEjercicioPresupuestoFuenteFinanciamiento,
  EstadoFinancieroPresupuestal, edoAnaliticoPrestoEgresosClasifObjetoGasto,
  edoAnaliticodelPrestoEgresosClasifEconimica, IndicadoresPosturaFiscal,

  //Información Programática
  GastoCategoriaProgramatica, IndicadoresResultados, ProgramasProyectosInversion,

  //Información Programática
  ProgramaAnualEvaluaciones, ResultadoProgramaAnualEvaluaciones,
  InventarioBienesMuebles, InventarioBienesImuebles,
  CuentaPublica2022,
  InformaciónContableCP,
  informacionPresupuestalCP,
  informacionProgramaticaCP,
  anexosCP,
  infintegconsol,
  leydisciplinafinancieraCP,
  informacionadicionalCP,
  informaciondecreto90PEEH,
  informacionLPCGEH,
  ProgramaAnualAdquisiciones,
  CalendarioGastos,
  AprobacionLeyIngresosPresupuestos,
  informacionNormasTV,
  LeyDisciplinaFianaciera,
} from "../json/anioArmonizacion";

export const obtenerDatosSeleccionados = (selectedItemIndex) => {
  let datosSeleccionados = [];

  switch (selectedItemIndex) {

    //Información Contable
    case '1.- Estado de Situación Financiera':
      datosSeleccionados = estadoSituacionFinanciera;
      break;
    case '2.- Estado de Actividades':
      datosSeleccionados = estadoActividades;
      break;
    case '3.- Estado de Variación en la Hacienda Pública':
      datosSeleccionados = estadoVariacionHaciendaPublica;
      break;
    case '4.- Estado de Cambios en la Situación Financiera':
      datosSeleccionados = estadoCambiosSituacionFinanciera;
      break;
    case '5.- Informe de Pasivos Contingentes':
      datosSeleccionados = informePasivosContingentes;
      break;
    case '6.- Notas a los Estados Financieros':
      datosSeleccionados = notasEstadosFinancieros;
      break;
    case '7.- Estado Analítico del Activo':
      datosSeleccionados = estadoAnalíticoActivo;
      break;
    case '8.- Estado de Flujos de Efectivo':
      datosSeleccionados = estadoFlujosEfectivo;
      break;
    case '9.- Estado Analítico de la Deuda y Otros Pasivos':
      datosSeleccionados = estadoAnaliticoDeudaOtrosPasivo;
      break;
    // case 'j) Conciliacion entre los Ingresos Presupuestarios y Contables':
    //   datosSeleccionados = conciliacionContablePresupuestal;
    //   break;
    // case 'k) Conciliacion entre los Egresos Presupuestarios y los Gastos Contables':
    //   datosSeleccionados = conciliacionContablePresupuestalE;
    //   break;

    //Información Presupuestaría
    case '1.- Estado Analítico de Ingresos':
      datosSeleccionados = estadoAnaliticoIngresos;
      break;
    case '02.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Económica':
      datosSeleccionados = estadoAnaliticoPresupuestoEgresosCE;
      break;
    case '03.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación por Objeto del Gasto':
      datosSeleccionados = estadoAnaliticoPresupuestoEgresosCOG;
      break;
    case 'c) Estado Analítico Presupuesto Egresos Clasificación por Objeto del Gasto':
      datosSeleccionados = estadoAnaliticoPresupuestoEgresosCE;
      break;
    case '04.- Intereses de la Deuda':
      datosSeleccionados = InteresesDeuda;
      break;
    case '05.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Administrativa':
      datosSeleccionados = EstadoAnaliticoPresupuestoEgresosCA;
      break;
    case '06.- Estado Analítico del Ejercicio del Presupuesto de Egresos - Clasificación Funcional':
      datosSeleccionados = EstadoAnaliticoPresupuestoEgresosCF;
      break;
    case '07.- Endeudamiento Neto':
      datosSeleccionados = EndeudamientoNeto;
      break;
    // case 'Estado Analítico del Ejercicio del Presupuesto de Egresos':
    //   datosSeleccionados = EstadoAnaliticoEjercicioPresupuestoEgresos;
    //   break;
    // case 'Estado Analítico del Presupuesto de Egresos en Clasificación Programática':
    //   datosSeleccionados = EstadoAnaliticoPresupuestoEgresosClasificacionProgramatica;
    //   break;
    // case 'Estado Analítico del Ejercicio del Presupuesto por Fuente de Financiamiento':
    //   datosSeleccionados = EstadoAnaliticoEjercicioPresupuestoFuenteFinanciamiento;
    //   break;
    // case 'Estado Financiero Presupuestal':
    //   datosSeleccionados = EstadoFinancieroPresupuestal;
    //   break;
    // case 'Estado Analítico del Presupuesto de Egresos en Clasificación por Objeto del Gasto':
    //   datosSeleccionados = edoAnaliticoPrestoEgresosClasifObjetoGasto;
    //   break; 
    // case 'Estado Analítico del Presupuesto de Egresos en Clasificación Económica (Tipo de Gasto)':
    //   datosSeleccionados = edoAnaliticodelPrestoEgresosClasifEconimica;
    //   break;
    // case 'e) Flujo de fondos (Indicadores de Postura Fiscal':
    //   datosSeleccionados = IndicadoresPosturaFiscal;
    //   break;      
    
    //Información Programática
    case '01.- Gasto por Categoría Programática':
      datosSeleccionados = GastoCategoriaProgramatica;
      break;
    case '02.- Indicadores de Postura Fiscal':
      datosSeleccionados = IndicadoresPosturaFiscal;
      break;
    case '03.- Programas y Proyectos de Inversión':
      datosSeleccionados = ProgramasProyectosInversion;
      break;
    case '04.- Indicadores de Resultados':
      datosSeleccionados = IndicadoresResultados;
      break;

    //Otras Obligaciones LGCG
    case 'Programa Anual de Evaluaciones':
      datosSeleccionados = ProgramaAnualEvaluaciones;
      break;
    case 'Resultado del Programa Anual de Evaluaciones':
      datosSeleccionados = ResultadoProgramaAnualEvaluaciones;
      break;

    //Inventario de Bienes
    case 'Inventario de Bienes Muebles':
      datosSeleccionados = InventarioBienesMuebles;
      break;
    case 'Inventario de Bienes Imuebles':
      datosSeleccionados = InventarioBienesImuebles;
      break;

    //Normas Título V
     case 'Normas Título V':
      datosSeleccionados = informacionNormasTV;
      break;
    
    //Ley de Disciplina Fianaciera
     case 'Ley de Disciplina Fianaciera':
      datosSeleccionados = LeyDisciplinaFianaciera;
      break;
      
    //Programa Anual de Adquisiciones
    //case 'Programa Anual de Adquisiciones 2022':
    //  datosSeleccionados = ProgramaAnualAdquisiciones;
    //  break;

    //Calendario de Gastos
    case 'Calendario de Gastos 2022':
      datosSeleccionados = CalendarioGastos;
      break;
    
    //Cuenta Pública
    case 'Cuenta Pública':
      datosSeleccionados = CuentaPublica2022;
      break;
    case 'Información Contable CP':
      datosSeleccionados = InformaciónContableCP;
      break;
    case 'Información Presupuestal':
      datosSeleccionados = informacionPresupuestalCP;
      break;
    case 'Información Programática CP':
      datosSeleccionados = informacionProgramaticaCP;
      break;
    case 'Anexos':
      datosSeleccionados = anexosCP;
      break;
    case 'Inf. para integrar y consolidar':
      datosSeleccionados = infintegconsol;
      break;
    case 'Ley de Disciplina Anual':
      datosSeleccionados = leydisciplinafinancieraCP;
      break;   
    case 'Información adicional':
      datosSeleccionados = informacionadicionalCP;
      break;
    case 'Información del decreto no. 90 PEEH':
      datosSeleccionados = informaciondecreto90PEEH;
      break;
    case 'Información de la LPCGEH':
      datosSeleccionados = informacionLPCGEH;
      break;

    //case 'Normas (Titulo Quinto LGCG)':
     // datosSeleccionados = AprobacionLeyIngresosPresupuestos;
      //break;

    default:
      datosSeleccionados = [];
  }

  return datosSeleccionados;
};


