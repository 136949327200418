export const links = [
    {
        "nombre" : "¿Quienes Somos?",
        "link" : "/QuienesSomos",
    },
    {
        "nombre" : "Oferta Educativa",
        "link" : "/OfertaEducativa",
    },
    {
        "nombre" : "Estudiantes",
        "link" : "/Estudiantes",
    },
    {
        "nombre" : "Extensión y Vinculación",
        "link" : "/ExtensionVinculacion",
    },
    {
        "nombre" : "Normatividad",
        "link" : "/Normatividad",
    },
    {
        "nombre" : "Transparencia",
        "link" : "/Transparencia",
        "submenu" : [
            {
                "subMenuNombre" : "48 Rubros",
                "subMenuUrl" : "/Transparencia/Rubros"
            },
            {
                "subMenuNombre" : "Rubros Anteriores",
                "subMenuUrl" : "/Transparencia/Rubros/RubrosHistoricos"
            },
            {
                "subMenuNombre" : "PADA",
                "subMenuUrl" : "/Transparencia/Pada"
            },    
            {
                "subMenuNombre" : "Armonización Contable",
                "subMenuUrl" : "/ArmonizacionContable",
            },    
            {
                "subMenuNombre" : "Disciplina Financiera",
                "subMenuUrl" : "/DisciplinaFinanciera"
            },    
            {
                "subMenuNombre" : "PAAAyS",
                "subMenuUrl" : "Transparencia/Paaays"
            }
        ]
    },
    {
        "nombre" : "Preguntas Frecuentes",
        "link" : "/PreguntasFrecuentes",
    }    
]